import apiBase from './base'

export const signIn = (email: string, password: string): Promise<SignInResponse> => {
  return apiBase.doPost('/api/users/sessions/sign_in', { email, password })
}

export const signOut = (): Promise<SignOutResponse> => {
  return apiBase.doDelete('/api/users/sessions/log_out')
}

export const sendMagicLink = (email: string, code: string): Promise<SendMagicLinkResponse> => {
  return apiBase.doPost('/api/users/sessions/magic_link', { email, code })
}

export const verifyMagicLink = (token: string, code: string, temp: string): Promise<SignInResponse> => {
  return apiBase.doPost(`/api/users/sessions/magic_link/verify?token=${token}&code=${code}&temp=${temp}`)
}

export const getPasskeyChallenge = (): Promise<PasskeyChallengeResponse> => {
  return apiBase.doGet('/api/users/sessions/passkey/challenge')
}

export const signInWithPasskey = (data: SignInPasskeyRequest): Promise<SignInResponse> => {
  return apiBase.doPost('/api/users/sessions/passkey/verify', data)
}

export const ping = async (): Promise<PingResponse> => {
  return await apiBase.doGet('/api/users/sessions/ping')
}

export const sendResetPasswordInstructions = async (
  email: string,
  resetByDashboard: boolean = false
): Promise<SendResetPasswordInstructionsResponse> => {
  return await apiBase.doPost('/api/users/passwords/reset/new', { email, reset_by_dashboard: resetByDashboard })
}

export const resetPassword = async (newPassword: string, token: string | undefined): Promise<ResetPasswordResponse> => {
  return await apiBase.doPost('/api/users/passwords/reset', { new_password: newPassword, token })
}

export const validateResetPasswordToken = async (token: string): Promise<ResetPasswordValidateTokenResponse> => {
  return await apiBase.doPost('/api/users/passwords/token/validate', { token })
}

export const updatePassword = async (newPassword: string): Promise<ApiNoContentResponse> => {
  return await apiBase.doPatch('/api/users/users/update_password', { new_password: newPassword })
}

export const signInWithAuthenticator = async (data: SignInAuthenticatorRequest): Promise<SignInResponse> => {
  return await apiBase.doPost('/api/users/sessions/authenticator/verify', data)
}

export const getPasswordChangesDetails = async (): Promise<PasswordChangesDetailsResponse> => {
  return await apiBase.doGet('/api/users/users/password_log_details')
}

export const generateStripeLoginLink = async (): Promise<StripeLoginLinkResponse> => {
  return await apiBase.doPost('/api/users/sessions/stripe/login_link')
}

export const generatePasswordResetToken = async (id: string): Promise<PasswordResetTokenResponse> => {
  return await apiBase.doPost('/api/users/passwords/token/new', { id })
}

export default {
  ping,
  signIn,
  signOut,
  sendMagicLink,
  verifyMagicLink,
  getPasskeyChallenge,
  signInWithPasskey,
  sendResetPasswordInstructions,
  resetPassword,
  validateResetPasswordToken,
  updatePassword,
  signInWithAuthenticator,
  getPasswordChangesDetails,
  generateStripeLoginLink,
  generatePasswordResetToken
}
