import { ref } from 'vue'
import { defineStore } from 'pinia'
import { setCookie, getCookie, deleteCookie } from '../services/cookies'

const SESSION_COOKIE_NAME = 'session'

export const useAuthStore = defineStore('auth', () => {
  const loaded = ref(false)
  const token = ref('')
  const isAuthenticated = ref(false)

  function store(newToken: string) {
    token.value = newToken
    setCookie(SESSION_COOKIE_NAME, token.value, 365)
  }

  function loadTokenFromCookie() {
    const existingToken = getCookie(SESSION_COOKIE_NAME)
    token.value = existingToken
    loaded.value = true
  }

  function getToken(): string {
    if (!loaded.value || token.value.toString().length == 0) loadTokenFromCookie()
    return token.value
  }

  function clear() {
    loaded.value = false
    token.value = ''
    deleteCookie(SESSION_COOKIE_NAME)
  }

  return {
    token,
    store,
    loadTokenFromCookie,
    getToken,
    clear,
    loaded,
    isAuthenticated
  }
})
