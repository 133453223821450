import { ref } from 'vue'
import axios from 'axios'
import { defineStore } from 'pinia'
import api from '@/services/api/settings'
import { BUSINESS_SETTINGS } from '@/utils/constants'
import { getError } from '@/utils/errors'

export const useSettingStore = defineStore('setting', () => {
  const publicSettings = ref<{ [key: string]: string }>({})
  const settings = ref<{ [key: string]: Setting }>({})
  const loaded = ref(false)
  const bucketUrl = import.meta.env.VITE_S3_BUCKET_URL
  const settingsS3Path = import.meta.env.VITE_S3_SETTINGS_PATH
  const SETTINGS_LOCALSTORAGE = 'dashboard-public-settings'

  async function loadSettings() {
    if (!loaded.value) {
      Object.values(BUSINESS_SETTINGS).forEach((key) => {
        if (!settings.value[key]) {
          settings.value[key] = { id: '', key, value: '', apps: ['dashboard', 'web'] }
        }
      })

      const result = await api.getSettings()

      if (!result.error) {
        result.forEach((setting) => {
          settings.value[setting.key] = setting
        })
        loaded.value = true
      }
    }
  }

  async function loadPublicSettings() {
    await loadPublicSettingsFromLocalStorage()

    const s3Result = await loadPublicSettingsFromS3()

    if (s3Result.success && s3Result.data) {
      publicSettings.value = s3Result.data
      updateLocalStorage()
    } else {
      const dbResult = await loadPublicSettingsFromDB()

      if (dbResult.success && dbResult.data) {
        publicSettings.value = dbResult.data
        updateLocalStorage()
      }
    }
  }

  async function saveSetting(setting: Setting): Promise<SaveSettingsResponse> {
    if (setting.id) {
      return await api.updateSetting(setting.id, setting.key, setting.value, setting.apps)
    } else {
      const result = await api.createSetting(setting.key, setting.value, setting.apps)

      if (!result.error) settings.value[setting.key].id = result.id

      return result
    }
  }

  async function loadPublicSettingsFromS3() {
    try {
      const result = await axios.get(`${bucketUrl}${settingsS3Path}?t=${Date.now()}`)
      return { success: true, data: result.data }
    } catch (error: unknown) {
      return { success: false, error: error }
    }
  }

  async function loadPublicSettingsFromDB() {
    const response = await api.getPublicSettings('dashboard')

    if (!response.error) {
      return { success: true, data: response }
    } else {
      return { success: false, error: getError(response) }
    }
  }

  async function loadPublicSettingsFromLocalStorage() {
    const storedSettings = localStorage.getItem(SETTINGS_LOCALSTORAGE)

    if (storedSettings) {
      try {
        const parsedSettings = JSON.parse(storedSettings)

        if (parsedSettings && typeof parsedSettings === 'object' && !Array.isArray(parsedSettings)) {
          publicSettings.value = parsedSettings
        }
      } catch (error: unknown) {
        // Do nothing
      }
    }
  }

  async function updateLocalStorage() {
    if (publicSettings.value && Object.keys(publicSettings.value).length > 0) {
      localStorage.setItem(SETTINGS_LOCALSTORAGE, JSON.stringify(publicSettings.value))
    }
  }

  return {
    loaded,
    settings,
    saveSetting,
    loadSettings,
    publicSettings,
    loadPublicSettings
  }
})
