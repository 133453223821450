import { ref } from 'vue'
import { defineStore } from 'pinia'
import { STATUS_CODES } from '@/services/api/base'
import apiSessions from '@/services/api/sessions'
import { useAuthStore } from '@/stores/auth'
import { getCookie, setCookie, deleteCookie } from '@/services/cookies'

export const useSessionStore = defineStore('session', () => {
  const loaded = ref(false)
  const user = ref<UserSession>()

  async function signIn(email: string, password: string): Promise<SignInResponse> {
    return apiSessions.signIn(email, password)
  }

  async function signOut(): Promise<{ error?: string }> {
    const authStore = useAuthStore()

    if (authStore.getToken()) {
      const response = await apiSessions.signOut()

      if (!response.error) {
        authStore.clear()
        deleteCookie('user')
      } else {
        if (response.status_code == STATUS_CODES.UNAUTHORIZED) {
          authStore.clear()
          deleteCookie('user')
        } else {
          return response
        }
      }
    }
    return {}
  }

  async function getPasskeyChallenge(): Promise<PasskeyChallengeResponse> {
    return apiSessions.getPasskeyChallenge()
  }

  async function signInWithPasskey(data: SignInPasskeyRequest): Promise<SignInResponse> {
    return apiSessions.signInWithPasskey(data)
  }

  async function signInWithAuthenticator(code: string, temp: string): Promise<SignInResponse> {
    return await apiSessions.signInWithAuthenticator({ code, temp })
  }

  async function sendMagicLink(email: string, code: string): Promise<SendMagicLinkResponse> {
    return await apiSessions.sendMagicLink(email, code)
  }

  async function verifyMagicLink(token: string, code: string, tempId: string): Promise<SignInResponse> {
    return await apiSessions.verifyMagicLink(token, code, tempId)
  }

  async function ping(): Promise<PingResponse> {
    return await apiSessions.ping()
  }

  async function sendResetPasswordInstructions(
    email: string,
    resetByDashboard: boolean
  ): Promise<SendResetPasswordInstructionsResponse> {
    return await apiSessions.sendResetPasswordInstructions(email, resetByDashboard)
  }

  async function resetPassword(newPassword: string, token: string | undefined): Promise<ResetPasswordResponse> {
    return await apiSessions.resetPassword(newPassword, token)
  }

  async function validateResetPasswordToken(token: string): Promise<ResetPasswordValidateTokenResponse> {
    return await apiSessions.validateResetPasswordToken(token)
  }

  async function generatePasswordResetToken(id: string): Promise<PasswordResetTokenResponse> {
    return await apiSessions.generatePasswordResetToken(id)
  }

  function getUser(): UserSession | undefined {
    if (!loaded.value) {
      user.value = getUserFromCookies()
      loaded.value = true
    }

    return user.value
  }

  function storeUser(userToStore: UserSession | undefined) {
    if (userToStore) {
      user.value = userToStore
      setCookie('user', JSON.stringify({ ...userToStore }))
    }
    loaded.value = true
  }

  function getUserFromCookies() {
    const storedUser = getCookie('user')
    if (storedUser) {
      return JSON.parse(storedUser)
    }
  }

  function updatePassword(newPassword: string): Promise<ApiNoContentResponse> {
    return apiSessions.updatePassword(newPassword)
  }

  function getPasswordChangesDetails(): Promise<PasswordChangesDetailsResponse> {
    return apiSessions.getPasswordChangesDetails()
  }

  async function generateStripeLoginLink(): Promise<StripeLoginLinkResponse> {
    return apiSessions.generateStripeLoginLink()
  }

  return {
    user,
    ping,
    signIn,
    signOut,
    getPasskeyChallenge,
    signInWithPasskey,
    sendMagicLink,
    verifyMagicLink,
    sendResetPasswordInstructions,
    resetPassword,
    validateResetPasswordToken,
    loaded,
    storeUser,
    getUser,
    getUserFromCookies,
    updatePassword,
    signInWithAuthenticator,
    getPasswordChangesDetails,
    generateStripeLoginLink,
    generatePasswordResetToken
  }
})
